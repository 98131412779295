import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { articlesApi } from "engine/api";
import { routes } from "engine/routes";
import { IGetFilteredArticlesRequest } from "pages/articles/articles-overview/types";
import { push } from "redux-first-history";
import { IArticle } from "./types";

export const getArticles = createAsyncThunk<IArticle[]>(
  "articles/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await articlesApi.getArticles();

      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const getFilteredArticles = createAsyncThunk<IArticle[], IGetFilteredArticlesRequest>(
  "articles/getFilteredArticles",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await articlesApi.getFilteredArticles(requestData);

      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const getArticlesByIds = createAsyncThunk<IArticle[], string[]>(
  "articles/getArticlesByIds",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await articlesApi.getArticlesByIds(requestData);

      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const getArticleById = createAsyncThunk<IArticle, string>(
  "articles/getArticlesById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await articlesApi.getArticleById(id);

      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const addArticle = createAsyncThunk<any, IArticle>(
  "articles/addArticle",
  async (article, { rejectWithValue, dispatch }) => {
    try {
      const response = await articlesApi.addArticle(article);

      if (response.status === 200) {
        message.success("Article created successfully");
        dispatch(push(routes.dashboard.articles().overview));
      }

      return {};
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const editArticle = createAsyncThunk<any, IArticle>(
  "articles/editArticle",
  async (article, { rejectWithValue }) => {
    try {
      console.log(article);
      const response = await articlesApi.editArticle(article);

      if (response.status === 200) {
        message.success("Article updated successfully");
      }

      return {};
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const deleteArticle = createAsyncThunk<any, string>(
  "articles/deleteArticle",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await articlesApi.deleteArticle(id);

      if (response.status === 200) {
        message.success("Article removed successfully");
        dispatch(push(routes.dashboard.articles().overview));
      }

      return {};
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const translateContent = createAsyncThunk<any>(
  "articles/translate",
  async (requestData, { rejectWithValue }) => {
    try {
      // @ts-ignore
      const { data } = await articlesApi.translateContent(requestData);
      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);
